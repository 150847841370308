(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name login.forgotten.controller:ForgottenCtrl
   *
   * @description
   *
   */
  angular
    .module('login.forgotten')
    .controller('ForgottenCtrl', ForgottenCtrl);

  function ForgottenCtrl(Restangular) {
    var vm = this;
    vm.ctrlName = 'ForgottenCtrl';
    vm.submit = submit;
    vm.errorMessage = '';
    vm.success = false;

    vm.form = {
      identifier: ''
    };

    function submit() {
      Restangular.all('forgotten').post(vm.form).then(function() {
        vm.success = true;
        vm.form.identifier = '';
      }, function(error) {
        console.log('e', error);
        vm.errorMessage = error.data.errors[0].message;
      });
    }
  }
}());
